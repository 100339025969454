@font-face {
	font-family: 'ShippingIcon';
	src:url('../fonts/ShippingIcon.eot?-3ztn3g');
	src:url('../fonts/ShippingIcon.eot?#iefix-3ztn3g') format('embedded-opentype'),
		url('../fonts/ShippingIcon.ttf?-3ztn3g') format('truetype'),
		url('../fonts/ShippingIcon.woff?-3ztn3g') format('woff'),
		url('../fonts/ShippingIcon.svg?-3ztn3g#ShippingIcon') format('svg');
	font-weight: normal;
	font-style: normal;
}

.icon {
	font-family: 'ShippingIcon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-air6:before {
	content: "\e003";
}
.icon-airplane68:before {
	content: "\e006";
}
.icon-barscode:before {
	content: "\e007";
}
.icon-boat17:before {
	content: "\e00a";
}
.icon-clipboard52:before {
	content: "\e011";
}
.icon-commercial15:before {
	content: "\e012";
}
.icon-crane7:before {
	content: "\e017";
}
.icon-delivery18:before {
	content: "\e01b";
}
.icon-delivery25:before {
	content: "\e022";
}
.icon-delivery27:before {
	content: "\e024";
}
.icon-frontal19:before {
	content: "\e033";
}
.icon-locked14:before {
	content: "\e03b";
}
.icon-ocean3:before {
	content: "\e043";
}
.icon-packages2:before {
	content: "\e04c";
}
.icon-train20:before {
	content: "\e05a";
}
.icon-play:before {
	content: "\e600";
}
.icon-pause:before {
	content: "\e601";
}
.icon-envelope:before {
	content: "\e602";
}
.icon-phone:before {
	content: "\e603";
}
.icon-arrow-right:before {
	content: "\f144";
}
.icon-camera:before {
	content: "\f1d1";
}
.icon-cart:before {
	content: "\f1da";
}
.icon-check:before {
	content: "\f1f1";
}
.icon-chevron-down:before {
	content: "\f204";
}
.icon-chevron-left:before {
	content: "\f205";
}
.icon-chevron-right:before {
	content: "\f206";
}
.icon-chevron-up:before {
	content: "\f207";
}
.icon-close:before {
	content: "\f216";
}
.icon-dribbble:before {
	content: "\f286";
}
.icon-facebook:before {
	content: "\f2aa";
}
.icon-google-plus:before {
	content: "\f339";
}
.icon-image-area:before {
	content: "\f362";
}
.icon-magnify:before {
	content: "\f3ab";
}
.icon-menu:before {
	content: "\f3bc";
}
.icon-settings:before {
	content: "\f4ae";
}
.icon-star:before {
	content: "\f4d4";
}
.icon-twitter:before {
	content: "\f535";
}
.icon-video:before {
	content: "\f545";
}
.icon-youtube-play:before {
	content: "\f591";
}
.icon-logo:before {
	content: "\41";
}
